<template>
  <div>
    <!-- <div v-if="loading" class="showload">Loading...</div>
    <div v-else class="none"> -->
      <span class="timer">0{{ minutes }}</span><span>:</span><span class="timer">{{ seconds }}</span>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name : 'TimePoint',
  data() {
    return {
      minutes: 0,
      seconds: '00',
      loading: true,
    };
  },
  mounted() {
    this.startCountDown();
    setInterval(this.startCountDown, 1000);
  },
  methods: {
    startCountDown() {
      this.loading = false;
      
      const countDownDate = Math.floor(Date.now() / 1000);
      const distance = 60 - (countDownDate % 60);

      this.minutes = Math.floor(distance / 60);
      this.seconds = ('0' + Math.floor(distance % 60)).slice(-2);

      if (distance === 60 || distance === 58) {
        window.location.reload();
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
.timer {
  font-size: 24px;
}
.showload {
  display: block;
}
.none {
  display: none;
}
</style>