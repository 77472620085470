<template>
    <div class="top-nav">
       <img src="../assets/png/logo.png" class="logo" alt="">
       <div class="pl-5 ml-5">
          <img src="../assets/png/message.png" class="message" alt="">
          <img src="../assets/png/download.png" class="download" alt="">
       </div>
    </div>
    <br><br>
    <div data-v-7dd1adab="" data-v-5d71c3fd="" class="Wallet__C">
   <div data-v-7dd1adab="" class="Wallet__C-balance">
      <div data-v-7dd1adab="" class="Wallet__C-balance-l1">
         <div data-v-7dd1adab="">₹13.02</div>
      </div>
      <div data-v-7dd1adab="" class="Wallet__C-balance-l2">
         <svg data-v-7dd1adab="" class="svg-icon icon-lottyWallet">
            <use xlink:href="#icon-lottyWallet"></use>
         </svg>
         <div data-v-7dd1adab="">Wallet balance</div>
      </div>
      <div data-v-7dd1adab="" class="Wallet__C-balance-l3">
         <div data-v-7dd1adab="">Withdraw</div>
         <div data-v-7dd1adab="">Deposit</div>
      </div>
   </div>
   <div data-v-17d56002="" data-v-5d71c3fd="" class="GameList__C">
   <div data-v-17d56002="" class="GameList__C-item active">
      <div data-v-17d56002="">Win Go<br>1Min</div>
   </div>
   <div data-v-17d56002="" class="GameList__C-item">
      <div data-v-17d56002="">Win Go<br>3Min</div>
   </div>
   <div data-v-17d56002="" class="GameList__C-item">
      <div data-v-17d56002="">Win Go<br>5Min</div>
   </div>
   <div data-v-17d56002="" class="GameList__C-item">
      <div data-v-17d56002="">Win Go<br>10Min</div>
   </div>
</div>
<div data-v-3e4c6499="" class="TimeLeft__C">
   <div data-v-3e4c6499="" class="TimeLeft__C-rule">
      <svg data-v-3e4c6499="" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
         <path data-v-3e4c6499="" d="M23.67 3H12.33C6.66 3 5.25 4.515 5.25 10.56V27.45C5.25 31.44 7.44 32.385 10.095 29.535L10.11 29.52C11.34 28.215 13.215 28.32 14.28 29.745L15.795 31.77C17.01 33.375 18.975 33.375 20.19 31.77L21.705 29.745C22.785 28.305 24.66 28.2 25.89 29.52C28.56 32.37 30.735 31.425 30.735 27.435V10.56C30.75 4.515 29.34 3 23.67 3ZM11.67 18C10.845 18 10.17 17.325 10.17 16.5C10.17 15.675 10.845 15 11.67 15C12.495 15 13.17 15.675 13.17 16.5C13.17 17.325 12.495 18 11.67 18ZM11.67 12C10.845 12 10.17 11.325 10.17 10.5C10.17 9.675 10.845 9 11.67 9C12.495 9 13.17 9.675 13.17 10.5C13.17 11.325 12.495 12 11.67 12ZM24.345 17.625H16.095C15.48 17.625 14.97 17.115 14.97 16.5C14.97 15.885 15.48 15.375 16.095 15.375H24.345C24.96 15.375 25.47 15.885 25.47 16.5C25.47 17.115 24.96 17.625 24.345 17.625ZM24.345 11.625H16.095C15.48 11.625 14.97 11.115 14.97 10.5C14.97 9.885 15.48 9.375 16.095 9.375H24.345C24.96 9.375 25.47 9.885 25.47 10.5C25.47 11.115 24.96 11.625 24.345 11.625Z" fill="currentColor"></path>
      </svg>
      How to play
   </div>
   <div data-v-3e4c6499="" class="TimeLeft__C-name">Win Go 1Min</div>
   <div data-v-3e4c6499="" class="TimeLeft__C-num">
      <div data-v-3e4c6499="" class="n8"></div>
      <div data-v-3e4c6499="" class="n3"></div>
      <div data-v-3e4c6499="" class="n2"></div>
      <div data-v-3e4c6499="" class="n8"></div>
      <div data-v-3e4c6499="" class="n9"></div>
   </div>
   <div data-v-3e4c6499="" class="TimeLeft__C-id">20240712010832</div>
   <div data-v-3e4c6499="" class="TimeLeft__C-text">Time remaining</div>
   <div data-v-3e4c6499="" class="TimeLeft__C-time">
      <div data-v-3e4c6499="">0</div>
      <div data-v-3e4c6499="">0</div>
      <div data-v-3e4c6499="">:</div>
      <div data-v-3e4c6499="">3</div>
      <div data-v-3e4c6499="">9</div>
   </div>
</div>
</div>

 </template>
 <script>
    export default {
        name: "GamesView",
      
    }
    
 </script>