<template>
   <div>
     <!-- Buttons with explicit values in the @click directive -->
     <button @click="multiply(1)">1</button>
     <button @click="multiply(2)">2</button>
     <button @click="multiply(3)">3</button>
     <button @click="multiply(4)">4</button>
     <button @click="multiply(5)">5</button>
   </div>
   <div>
     <!-- Input field to take user input -->
     <input type="number" v-model.number="inputValue" @input="updateResult">
   </div>
   <p>Result: {{ result }}</p>
</template> 
 <script>
 export default{
   name: 'NotificationView',
   data() {
      return{
     inputValue: 1, // Default input value
     result: 1,     // Initial result value
     lastButtonValue: 1 // Store the last clicked button value
      }
   },
   methods: {
     multiply(buttonValue) {
       this.lastButtonValue = buttonValue; // Update the last clicked button value
       this.result = this.inputValue * buttonValue; // Calculate the result
     },
     updateResult() {
       this.result = this.inputValue * this.lastButtonValue; // Recalculate the result if input value changes
     }
   }
 }
 </script>
 