<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Add USDT Address</div>
                   </div>
                </div>
             </div>
          </div>
          <form>
          <div data-v-1726638e="" class="addBankCard__container-content">
             <div data-v-1726638e="" class="addBankCard__container-content-top"><img data-v-1726638e="" src="../assets/png/hint-d2d46a38.png"><span data-v-1726638e="">To ensure the safety of your funds, please bind your bank account</span></div>
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                    <svg data-v-24736190="" class="svg-icon icon-usdt1 icon icon" width="30px" height="30px">
                        <use xlink:href="#icon-usdt1"></use>
                    </svg>
                   Select Main Network
                </div>
                <select data-v-1726638e="" class="selectB" v-model="userdetails.bankname">
                   <option value="trc" selected>TRC</option>
                </select>
             </div>
             <!--验证收款人姓名-->
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                    <svg data-v-24736190="" class="svg-icon icon-usdt2 icon icon" width="30px" height="30px">
                        <use xlink:href="#icon-usdt2"></use>
                    </svg>
                   USDT Address
                </div>
                <input data-v-1726638e="" v-model="userdetails.account_number" placeholder="Please enter the usdt address" maxlength="50" ><!--v-if--><!--v-if-->
             </div>
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                    <svg data-v-24736190="" class="svg-icon icon-usdt3 icon icon" width="30px" height="30px">
                        <use xlink:href="#icon-usdt3"></use>
                    </svg>
                   Address Alias
                </div>
                <input data-v-1726638e="" v-model="userdetails.name" placeholder="Please enter the remark of the withdrawal address" maxlength="25">
             </div>
             <div data-v-1726638e="" class="addBankCard__container-content-btn"><button data-v-1726638e="" @click.prevent="addBank()" class="" style="background: var(--main-color);">Save</button></div>
          </div>
        </form>
            <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                <div class="van-toast__text">success</div>
            </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'AddUsdtAddressView',
         data(){
             return{
                userdetails: {
                   username: null,
                   bankname: null,
                   name: null,
                   account_number: null,
                   ifsc: null,
                }
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
         methods:{
          goBack() {
             window.history.back();
          },
          pop(message) {
             document.getElementById("snackbar").innerHTML = message;
             document.getElementById("snackbar").style.display = "";
             setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 3000);
         },
          addBank() {
            this.userdetails.username=localStorage.getItem('username');
                if(this.userdetails.account_number === null){
                    this.pop("Please Enter USDT Address");
                }
                else if(this.userdetails.name === null){
                    this.pop("Enter address alias");
                }
               else{
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("name", this.userdetails.name);
                     data.append("account_number", this.userdetails.account_number);
                     axios
                        .post(
                           "https://manager.jackpotclub.pro/trova/src/api/user_api_tcgames.php?add_wallet",
                           data
                        )
                        .then(res => {
                            if (res.data.error==true) {
                           //   this.loading();
                             console.log("Success", res.data.message);
                             this.pop(res.data.message);
                             setTimeout(() => { this.$router.push('UsdtWithdraw'); }, 1000);
                         } else {
                             
                             console.log("Error", res.data);
                             this.pop(res.data.message);
                         }  
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               }
            },
         }
     }
 </script>
<style>
    option{
        background: var(--main-color);
    }
        .van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>
