<template>
  <div id="app">
    <!-- <div v-if="loading" class="preloader">
      <img src=".\assets\png\logo.png" class="img-fluid" alt="Loading...">
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    // Simulate loading time or wait for an async operation
    setTimeout(() => {
      this.loading = false;
    }, 1000); // 2 seconds delay
  }
}
</script>
