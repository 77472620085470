<template>
    <div class="container teamReport">
         <div class="row header">
             <i @click="goBack" class="fa fa-angle-left back-button"></i>
             <p class="attendance">Vip</p>
         </div>
         <div class="vip_profile">
             <div class="vip__container">
                 <img src="../assets/png/8-ea087ede.png" class="profile_img" alt="">
                 <img src="../assets/png/0-78e1ab02.png" class="vip__tag" alt="" v-if="userdetails.vip === '0'">
                 <img src="../assets/png/1-953c5909.png" class="vip__tag" alt="" v-if="userdetails.vip === '1'">
                 <img src="../assets/png/2-c9b115fb.png" class="vip__tag" alt="" v-if="userdetails.vip === '2'">
                 <img src="../assets/png/3-fb0c8c43.png" class="vip__tag" alt="" v-if="userdetails.vip === '3'">
                 <img src="../assets/png/4-3c5b4bba.png" class="vip__tag" alt="" v-if="userdetails.vip === '4'">
                 <img src="../assets/png/5-5e49bae2.png" class="vip__tag" alt="" v-if="userdetails.vip === '5'">
                 <img src="../assets/png/6-4ee4b170.png" class="vip__tag" alt="" v-if="userdetails.vip === '6'">
                 <img src="../assets/png/7-907655eb.png" class="vip__tag" alt="" v-if="userdetails.vip === '7'">
                 <img src="../assets/png/8-fc7f2447.png" class="vip__tag" alt="" v-if="userdetails.vip === '8'">
                 <img src="../assets/png/9-5a25583e.png" class="vip__tag" alt="" v-if="userdetails.vip === '9'">
                 <img src="../assets/png/10-b06f6562.png" class="vip__tag" alt="" v-if="userdetails.vip === '10'">
                 <p>{{ userdetails.name }}</p>
             </div>
         </div>
         <div class="box__display">
             <div class="vip__box_6 text-center">
                 <p class="text-danger">{{ userdetails.exp }} EXP</p>
                 <p style="margin-top: -10px;">My experience</p>
             </div>
             <div class="vip__box_6 text-center">
                 <p><b>11</b> Days</p>
                 <p style="margin-top: -10px;">Payout time</p>
             </div>
         </div>
         <div data-v-92d3d2e1="" class="vip-content-tip">VIP level rewards are settled at 2:00 am on the 1st of every month</div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP1 Benefits level (3000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 60</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 30</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP2 Benefits level (30000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 180</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 90</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP3 Benefits level (200000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 690</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 290</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP4 Benefits level (2000000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 1290</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 690</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP5 Benefits level (20000000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 6900</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 1690</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-9bb5e81c="" data-v-92d3d2e1="" class="vip-content-weal">
             <div data-v-9bb5e81c="" class="slide">
                 <div data-v-9bb5e81c="" class="vip-content-weal-head ar-1px-b">
                     <svg data-v-9bb5e81c="" class="svg-icon icon-diamond">
                         <use xlink:href="#icon-diamond"></use>
                     </svg>
                     <h1 data-v-9bb5e81c="">VIP6 Benefits level (500000000EXP)</h1>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/1-cc76d91b.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Level up rewards</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 16900</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/2-b96560a8.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Monthly reward</h2>
                         <span data-v-9bb5e81c="">Each account can only receive 1 time per month</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/gold-4a60a059.png"> 4900</p>
                         <p data-v-9bb5e81c="">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-love">
                             <use xlink:href="#icon-love"></use>
                         </svg>
                         0
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/4-cf53b0e6.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Safe</h2>
                         <span data-v-9bb5e81c="">Increase the extra income of the safe</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal4">
                             <use xlink:href="#icon-weal4"></use>
                         </svg>
                         0.2% 
                         </p>
                     </div>
                 </div>
                 <div data-v-9bb5e81c="" class="vip-content-weal-con">
                     <div data-v-9bb5e81c=""><img data-v-9bb5e81c="" src="../assets/png/5-c23cdabd.png"></div>
                     <div data-v-9bb5e81c="">
                         <h2 data-v-9bb5e81c="">Rebate rate</h2>
                         <span data-v-9bb5e81c="">Increase income of rebate</span>
                     </div>
                     <div data-v-9bb5e81c="">
                         <p data-v-9bb5e81c="" class="max">
                         <svg data-v-9bb5e81c="" class="svg-icon icon-weal5">
                             <use xlink:href="#icon-weal5"></use>
                         </svg>
                         0.6% 
                         </p>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare" v-if="userdetails.vip==='0' || userdetails.vip==='1'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 60</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="active" v-if="userdetails.exp > 2999 && userdetails.vip1=== '0'">Received</button>
                         <button data-v-4e842459="" class="noActive" v-else>Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 30</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive" v-if="userdetails.exp > 2999 && userdetails.vip_monthly1=== '0'">Received</button>
                         <button data-v-4e842459="" class="noActive" v-else>Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare"  v-if="userdetails.vip==='2'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 180</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 90</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare"  v-if="userdetails.vip==='3'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 690</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 290</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare" v-if="userdetails.vip==='4'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 1290</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 690</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare"  v-if="userdetails.vip==='5'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 6900</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 1690</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-4e842459="" data-v-92d3d2e1="" class="vip-content-myWelfare" v-if="userdetails.vip==='6'">
             <div data-v-4e842459="" class="slideMy">
                 <div data-v-4e842459="" class="vip-content-myWelfare-head ar-1px-b">
                     <svg data-v-4e842459="" class="svg-icon icon-crown">
                         <use xlink:href="#icon-crown"></use>
                     </svg>
                     <h1 data-v-4e842459="">My benefits</h1>
                 </div>
                 <div data-v-4e842459="" class="vip-content-myWelfare-con">
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare1-eee87ee1.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 16900</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Level up rewards</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head">
                             <img data-v-4e842459="" src="../assets/png/welfare2-cf757d28.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/gold-4a60a059.png"> 4900</p>
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/love2-83aaa90e.png"> 0</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Monthly reward</h1>
                             <span data-v-4e842459="">Each account can only receive 1 time per month</span>
                         </div>
                         </div>
                         <button data-v-4e842459="" class="noActive">Received</button>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare4-5642a4c8.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.2%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Safe</h1>
                             <span data-v-4e842459="">Increase the extra income of the safe</span><!--v-if-->
                         </div>
                         </div>
                     </div>
                     <div data-v-4e842459="" class="cards">
                         <div data-v-4e842459="" class="card">
                         <div data-v-4e842459="" class="card-head tilt">
                             <img data-v-4e842459="" src="../assets/png/welfare5-8b250748.png">
                             <div data-v-4e842459="" class="card-head-mon">
                                 <p data-v-4e842459=""><img data-v-4e842459="" src="../assets/png/wallet1-8be3e605.png">0.6%</p>
                             </div>
                         </div>
                         <div data-v-4e842459="" class="card-bottom">
                             <h1 data-v-4e842459="">Rebate rate</h1>
                             <span data-v-4e842459="">Increase income of rebate</span>
                             <div data-v-4e842459="" class="viewD">Check the details</div>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div data-v-eaa4a307="" data-v-92d3d2e1="" class="vip-content-recordVsrule">
             <div data-v-eaa4a307="" class="vip-content-recordVsrule-head">
                 <button data-v-eaa4a307="" :class="{ active: currentTab === 'tab1' }" @click="setTab('tab1')">History</button>
                 <button data-v-eaa4a307="" @click="setTab('tab2')" :class="{ active: currentTab === 'tab2' }">Rules</button>
             </div>
         <div data-v-eaa4a307="" class="vip-content-recordVsrule-con" v-if="currentTab === 'tab1'">
             <div data-v-eaa4a307="" class="item ar-1px-b" v-for="rows in viprecord" :key="rows.id">
                 <div data-v-eaa4a307="" class="item-left">
                     <span data-v-eaa4a307="" class="green">Successfully received</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;" v-if="rows.reward_type==='Level up'">Successfully received [Level up]</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;" v-if="rows.reward_type==='Monthly'">Successfully received [Monthly bonus]</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;">{{ rows.created_at }}</span>
                 </div>
                 <div data-v-eaa4a307="" class="item-right">
                     <p data-v-eaa4a307=""><img data-v-eaa4a307="" src="../assets/png/gold-4a60a059.png"> {{ rows.reward_amount }}</p>
                     <p data-v-eaa4a307=""><img data-v-eaa4a307="" src="../assets/png/love-472917ef.png"> 0</p>
                 </div>
                 
             </div>
             <!-- <div data-v-eaa4a307="" class="item ar-1px-b">
                 
                 <div data-v-eaa4a307="" class="item-left">
                     <span data-v-eaa4a307="" class="red">Level maintenance</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;">Completion of level maintenance conditions [100% Completed]</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;">2024-09-01 00:03:18</span>
                 </div>
                 <div data-v-eaa4a307="" class="item-right" style="font-size: 12px;">
                     <span data-v-eaa4a307=""></span>
                     <span data-v-eaa4a307=""></span>
                     <span data-v-eaa4a307="">0 EXP</span>
                 </div>
                 
             </div> -->
             <!-- <div data-v-eaa4a307="" class="item ar-1px-b">
                 
                 <div data-v-eaa4a307="" class="item-left">
                     <span data-v-eaa4a307="" class="red">Level maintenance</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;">Completion of level maintenance conditions [100% Completed]</span>
                     <span data-v-eaa4a307="" style="font-size: 12px;">2024-08-01 00:03:18</span>
                 </div>
                 <div data-v-eaa4a307="" class="item-right" style="font-size: 12px;">
                     <span data-v-eaa4a307=""></span>
                     <span data-v-eaa4a307=""></span>
                     <span data-v-eaa4a307="">0 EXP</span>
                 </div>
                 
             </div> -->
             <!-- <button data-v-eaa4a307="">View All</button> -->
             <center class="mt-5 mb-5 text-white">No more</center>
         </div>
         <div data-v-eaa4a307="" class="con-content" v-if="currentTab === 'tab2'">
         <div data-v-eaa4a307="" class="con-content__title">
             <h1 data-v-eaa4a307="">VIP privileges</h1>
             <p data-v-eaa4a307="">VIP rule description</p>
         </div>
         <div data-v-eaa4a307="" class="con-content__rules">
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead" style="justify-content: center;">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title ">Upgrade standard</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">The IP member's experience points (valid bet amount) that meet the requirements of the corresponding rank will be promoted to the corresponding VIP level, the member's VIP data statistics period starts from 00:00:00 days VIP system launched.VIP level calculation is refreshed every 10 minutes! The corresponding experience level is calculated according to valid odds 1:1 !</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Upgrade order</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">The VIP level that meets the corresponding requirements can be promoted by one level every day, but the VIP level cannot be promoted by leapfrogging.</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Level maintenance</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">VIP members need to complete the maintenance requirements of the corresponding level within 30 days after the "VIP level change"; if the promotion is completed during this period, the maintenance requirements will be calculated according to the current level.</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Downgrade standard</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">If a VIP member fails to complete the corresponding level maintenance requirements within 30 days, the system will automatically deduct the experience points corresponding to the level. If the experience points are insufficient, the level will be downgraded, and the corresponding discounts will be adjusted to the downgraded level accordingly.</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Upgrade Bonus</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">The upgrade benefits can be claimed on the VIP page after the member reaches the VIP membership level, and each VIP member can only get the upgrade reward of each level once.</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Monthly reward</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">VIP members can earn the highest level of VIP rewards once a month.Can only be received once a month. Prizes cannot be accumulated. And any unclaimed rewards will be refreshed on the next settlement day. When receiving the highest level of monthly rewards this month Monthly Rewards earned in this month will be deducted e.g. when VIP1 earns 500 and upgrades to VIP2 to receive monthly rewards 500 will be deducted.</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Real-time rebate</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">The higher the VIP level, the higher the return rate, all the games are calculated in real time and can be self-rewarded!</p>
             </div>
             <div data-v-eaa4a307="" class="con-content__rules-item ruleHead">
                 <svg data-v-eaa4a307="" class="svg-icon icon-ruleHead">
                     <use xlink:href="#icon-ruleHead"></use>
                 </svg>
                 <div data-v-eaa4a307="" class="con-content__rules-item__title">Safe</div>
                 <div data-v-eaa4a307="" class="con-content__rules-item__titleRight"></div>
                 <p data-v-eaa4a307="">VIP members who have reached the corresponding level will get additional benefits on safe deposit based on the member's VIP level.</p>
             </div>
         </div>
         </div>
         </div>
    </div>
 </template>
  <script>
     import axios from 'axios';
      export default {
          name: 'VipView',
          data(){
              return{
                 giftcode: null,
                 currentTab: 'tab1',
                 userdetails: {
                 username: null,
                 balance: null,
                 usercode: null,
                 commision: "0",
                 intrest: "0",
                 notice: "Welcome",
 
              },
              viprecord: {},
              }
          },
          beforeCreate: function () {
            if (localStorage.getItem('username') == null)
                this.$router.push('Login');
           },
           created: function() {
              this.getUserdetails();
           },
           
          methods:{
             pop(message) {
                 document.getElementById("snackbar").innerHTML = message;
                 document.getElementById("snackbar").style.display = "";
                 setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1500);
             },
           goBack() {
              window.history.back();
           },
           getUserdetails(){
             this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.diman.online/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.usercode = this.Users[0].usercode;
                this.userdetails.balance = this.Users[0].balance;
                this.userdetails.userid = this.Users[0].user_id;
                this.userdetails.name = this.Users[0].name;
                this.userdetails.vip = this.Users[0].vip;
                this.userdetails.logindate = this.Users[0].last_login_date;
                this.userdetails.notice = this.Users[1].notice;
                this.userdetails.commision = this.Users[2].bonus;
                this.userdetails.days=this.Users[3].days;
                this.userdetails.status=this.Users[4].status;
             }).catch((err) => {
                 console.log(err);
             })
 
            axios.get('https://manager.diman.online/trova/src/api/me_api_tcgames.php?action=my_exp&user=' + this.userdetails.username).then((res) => {
                this.Exp = res.data.user_Data;
                console.log(this.Exp);
                this.userdetails.exp = this.Exp[0].amount;
             }).catch((err) => {
                 console.log(err);
             })
 
            axios.get('https://manager.diman.online/trova/src/api/me_api_tcgames.php?action=vip_button1&user=' + this.userdetails.username).then((res) => {
                this.Vip1 = res.data.user_Data;
                console.log(this.Vip1);
                this.userdetails.vip1 = this.Vip1[0].number;
                this.userdetails.vip_monthly1 = this.Vip1[1].number_monthly;
             }).catch((err) => {
                 console.log(err);
             })
 
             axios.get('https://manager.diman.online/trova/src/api/bet.php?action=viphistory&user=' + this.userdetails.username ).then((res) => {
                 this.viprecord = res.data;
                 console.log(this.viprecord);
             }).catch((err) => {
                 console.log(err);
             })
           },
           setTab(tab) {
                   this.currentTab = tab;
                },
          }
      }
  </script>
  <style>
     
     .attendance{
         margin-left: 42% !important;
     }
     .vip_profile{
         background-color: var(--main-color);
         height:8rem;
         width:107.6%;
         margin-left: -3.8%;
     }
     .vip__container
     {
         display: flex;
         gap: .63542rem;
     }
     .vip__container .profile_img
     {
         border-radius: 50%;
         width: 80px;
         height: 80px;
         margin-left: 0.522em;
     }
     .vip__container p
     {
         color: #fff;
         font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
         font-size: large;
         margin-left: -17%;
         margin-top: 2rem
     }
     .vip__tag
     {
         height: 30px;
     }
     .box__display{
         display: flex;
         gap: 1rem;
     }
     .vip__box_6{
         background: #fff;
         box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 15px;
         height: 60px;
         width: 50%;
         padding: 10px;
         font-size: 12px;
         border-radius: 5px;
         margin-top: -2rem;
     }
     .vip-content-tip[data-v-92d3d2e1] {
         margin-top: 1rem;
         border-radius: .33333rem;
         border: .01333rem solid var(--Dividing-line_color);
         padding: .36rem .46667rem;
         line-height: .57333rem;
         margin-bottom: .2rem;
         font-size: .6333rem;
         color: var(--text_color_L2)
     }
 
     @media screen and (max-width:500px) {
         .vip_profile{
         width:114.5%;
         margin-left: -10%;
         }
         .vip__container .profile_img
         {
             border-radius: 50%;
             width: 80px;
             height: 80px;
             margin-left: 2rem;
         }
     }
 </style>