<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Bank Account</div>
                   </div>
                </div>
             </div>
          </div>
          <div data-v-9ed9b8ef="" class="bankCard__container-content__card">
            <div data-v-9ed9b8ef="" class="bankCard__container-content__card-top"></div>
            <div data-v-9ed9b8ef="" class="bankCard__container-content__card-mid">
               <div data-v-9ed9b8ef="" class="line">
                  <div data-v-9ed9b8ef="" class="left">Bank name</div>
                  <div data-v-9ed9b8ef="" class="right">Bank of India</div>
               </div>
               <div data-v-9ed9b8ef="" class="line">
                  <div data-v-9ed9b8ef="" class="left">Bank account number</div>
                  <div data-v-9ed9b8ef="" class="right">497310****312</div>
               </div>
               <div data-v-9ed9b8ef="" class="line">
                  <div data-v-9ed9b8ef="" class="left">Phone number</div>
                  <div data-v-9ed9b8ef="" class="right">91629****</div>
               </div>
            </div>
            <div data-v-9ed9b8ef="">
               <div data-v-9ed9b8ef="" role="radio" class="van-radio" tabindex="0" aria-checked="true">
                  <div class="van-radio__icon van-radio__icon--round van-radio__icon--checked fa fa-check-circle" style="font-size: 14px; color:var(--main-color)">
                     <i class="van-badge__wrapper van-icon van-icon-success">
                        <!----><!----><!---->
                     </i>
                  </div>
                  <span class="van-radio__label" style="font-size: 13px; margin-left:3%;">Select</span>
               </div>
            </div>
         </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'BankCardView',
         data(){
             return{
                userdetails: {
                   username: null,
                   total: 0,
                },
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          getUserdetails(){
             this.userdetails.username = localStorage.getItem('username');
             axios.get('https://manager.jackpotclub.pro/trova/src/api/bet.php?action=withdrawrecord&user=' + this.userdetails.username + '&page1=' + this.betbox.page).then((res) => {
                 this.rechargerecord = res.data;
                 console.log(this.rechargerecord);
             }).catch((err) => {
                 console.log(err);
             })
          },
         }
     }
 </script>