<template>
  <div>
    <div v-if="showPopup" id="popup">
      <!-- आपका popup content यहाँ -->
      <button @click="closePopup">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name:'PopupView',
  
}
</script>
